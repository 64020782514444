import React, { useContext, useState, useEffect, useCallback } from "react";
import { debounce } from "lodash";
import { Link } from "react-router-dom";
import AppLayout from "../layouts/AppLayout";
import { AppContext } from "../contexts/AppContext";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import { PiSlidersHorizontal, PiSlidersHorizontalFill } from "react-icons/pi";
import "../styles/discover.scss";
import "../styles/member.scss";
import { HiMiniChevronDown } from "react-icons/hi2";
import { FiSearch } from "react-icons/fi";
import { MultiSelect } from "react-multi-select-component";
import Modal from "../components/Modal";
import { format } from "date-fns";

const Discover = () => {
  const { getmembers, getdiscoveroptions, getmember, getlists, savetolist, isLoading } = useContext(AppContext);

  const [members, setMembers] = useState([]);
  const [lists, setLists] = useState([]);
  const [member, setMember] = useState("");
  const [memberTerms, setMemberTerms] = useState();
  const [memberPartyHistory, setMemberPartyHistory] = useState();
  const [memberId, setMemberId] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [memberLoading, setmemberLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [loadingMore, setLoadingMore] = useState(true);
  const [fetchingLoading, setfetchingLoading] = useState(true);
  const [showListsDropdown, setShowListsDropdown] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [partyOptions, setPartyOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [chamberOptions, setChamberOptions] = useState([]);
  const [selectedPartyOptions, setSelectedPartyOptions] = useState([]);
  const [selectedStateOptions, setSelectedStateOptions] = useState([]);
  const [selectedChamberOptions, setSelectedChamberOptions] = useState([]);

  const memberdetails = [
    { label: 'Full Name', value: ((member.honorific_name != "" && member.honorific_name != "null" && member.honorific_name != null) ? member.honorific_name+" " : "") + member.first_name + " " + member.last_name },
    { label: 'State', value: member.state },
    { label: 'District', value: member.district },
    { label: 'Party', value: member.party_name },
    { label: 'Chamber', value: member.chamber },
    { label: 'Career', value: member.start_year+" - "+member.end_year },
  ];

  const memberdetails2 = [
    { label: 'Birth Year', value: member.birth_year },
    { label: 'Website', value: member.official_website_url },
    { label: 'Sponsored Legislative', value: member.sponsored_legislation_count },
    { label: 'Cosponsored Legislative', value: member.cosponsored_legislation_count },
    { label: 'Current Member', value: member.current_member },
    { label: 'Last Updated', value: member.update_date ? format(new Date(member.update_date.replace(" ", "T")), "do MMM yyyy") : '' },
  ];

  const limit = 24;

  const fetchMembers = useCallback(async () => {
    if (!hasMore) return;
    setLoadingMore(true);

    const partyValues = selectedPartyOptions.map(option => option.value);
    const stateValues = selectedStateOptions.map(option => option.value);
    const chamberValues = selectedChamberOptions.map(option => option.value);

    const response = await getmembers(searchTerm, limit, offset, partyValues, stateValues, chamberValues);
    if (response.length > 0) {

      // setMembers((prev) => [...prev, ...response]);
      setMembers(prev => {
          const allMembers = [...prev, ...response];
          // Remove duplicates using bioguide_id
          return Array.from(new Map(allMembers.map(m => [m.bioguide_id, m])).values());
      });
      setOffset((prev) => prev + limit);

    } else {
      setHasMore(false);
    }
    setfetchingLoading(false);
    setLoadingMore(false);
  }, [getmembers, searchTerm, offset, hasMore, selectedPartyOptions, selectedStateOptions, selectedChamberOptions]);

  const debouncedFetchMembers = useCallback(debounce(fetchMembers, 300), [searchTerm]);

  useEffect(() => {
    debouncedFetchMembers();
  }, [debouncedFetchMembers]);

  const handleSearch = (searchValue) => {
    setMembers([]);
    setOffset(0);
    setSearchTerm(searchValue);
    setHasMore(true);
  };

  const fetchOptions = async () => {
    try {
      const response = await getdiscoveroptions();
      if (response) {
        setPartyOptions(response.party);
        setStateOptions(response.state);
        setChamberOptions(response.chamber);
      }
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  useEffect(() => {
    setMember([]);
    const fetchMember = async () => {
      if(memberId != ''){
        setmemberLoading(true);
        const response = await getmember(memberId);
        setMember(response.basic[0]);
        setMemberTerms(response.terms);
        setMemberPartyHistory(response.partyHistory);
        setmemberLoading(false);
      }
    };

    fetchMember();
  }, [memberId]);

  const handleRemove = (value) => {
    setSelectedOptions(selectedOptions.filter(option => option !== value));
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const isBottom = scrollHeight - scrollTop <= clientHeight + 5; // Adjust buffer for precision
    if (isBottom && !loadingMore && hasMore) {
        fetchMembers();
    }
  };

  const saveToList = async (memberId, listId) => {
    if(memberId != ''){
      const response = await savetolist(memberId, listId);
      if(response == 'success'){
        setShowListsDropdown(false);
      }
    }
  }

  const fetchLists = async () => {
    const response = await getlists();
    setLists(response);
  };

  useEffect(() => {
    fetchLists();
    fetchOptions();
  }, []);

  useEffect(() => {
    setMembers([]);
    setHasMore(true);
    setOffset(0);
  }, [selectedPartyOptions, selectedStateOptions, selectedChamberOptions, searchTerm]);
  
  useEffect(() => {
    if (offset === 0) {
      fetchMembers();
      setfetchingLoading(true);
    }
  }, [offset, selectedPartyOptions, selectedStateOptions, selectedChamberOptions]);
  

  return (
    <AppLayout pageId="discover" pageName={"Discover"}>
      <section>
        {isLoading ? (
          <div className="flex justify-center w-full py-12">
            <Spinner color="#CCCCCC" size={24} />
          </div>
        ) : (
          <div>
            { (memberId != '' && member != '') && 
              <>
              <Modal heading="Member Info" isOpen={"true"} onClose={() => setMemberId(false)}>
                <div class="member-modal">
                  { memberLoading ? <div className="flex justify-center w-full py-12"> <Spinner color="#CCCCCC" size={24} /> </div> : 
                  <>
                  <div className="w-full flex flex-1 flex-col justify-between">
                  <div>
                  <div class="w-full flex justify-between">
                      <div>
                        {/* { savedAlready == 1 || member.ifsaved == 1 ? 
                        <button class="btn-share flex items-center space-x-1" onClick={unsaveIt}>
                            <FaHeart size={12}/> <span>Saved</span>
                        </button>
                        :
                        <button class="btn-share flex items-center space-x-1" onClick={saveIt}>
                            <FaRegHeart size={12}/> <span>Save</span>
                        </button>
                        } */}
                      </div>
                        {/* { sharedAlready == 1 || member.ifshared == 1 ? 
                        <button class="btn-share flex items-center space-x-2">
                            <span>Shared with team</span> <TbChecks size={16}/>
                        </button>
                        :
                        <button class="btn-share flex items-center space-x-3" onClick={shareWithTeam}>
                            <FiUsers size={16}/> <span>Share with team</span>
                        </button>
                        } */}
                  </div>
                  <div className="flex flex-col sm:flex-row sm:space-x-5">
                    <div>
                    <img className={`rounded-lg border-2 mx-auto ${member.party_name == 'Republican' ? 'border-red-500' : 'border-blue-500'}`} src={member.image_url}/>
                      <div>
                        <div className="relative mt-3">
                          <div class={`btn-modal-save-to-list w-full flex items-center justify-between space-x-1 ${showListsDropdown && '!rounded-b-none'}`} onClick={() => setShowListsDropdown(!showListsDropdown)}>
                              <span>Save to list</span> <HiMiniChevronDown size={16}/>
                          </div>
                          { showListsDropdown && 
                          <div className="save-list-dropdown">
                            {
                            lists.map((list, index) => {
                              return(
                                <div className={`dropdown-item`} onClick={() => saveToList(list.listid)}>{list.name}</div>
                              )
                            })
                            }
                          </div> 
                          }
                        </div>
                      </div>
                    </div>
                    <div className="flex-1">
                    <div className="grid grid-cols-1 md:grid-cols-2 sm:gap-10 mt-5 sm:mt-0">
                        <div className="">
                          <dl className="divide-y divide-neutral-800">
                            {
                              memberdetails.map((detail) => {
                                return(
                                  <div className="px-0 py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                                    <dt className="text-sm text-neutral-500">{detail.label}</dt>
                                    <dd className="mt-1 text-sm font-medium text-white sm:col-span-2 sm:mt-0">{detail.value}</dd>
                                  </div>
                                )
                              })
                            }
                          </dl>
                        </div>
                        
                        <div className="border-t border-neutral-800 sm:border-t-0">
                          <dl className="divide-y divide-neutral-800">
                            {
                              memberdetails2.map((detail) => {
                                return(
                                  <div className="px-0 py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                                    <dt className="text-sm text-neutral-500">{detail.label}</dt>
                                    <dd className="mt-1 text-sm font-medium text-white sm:col-span-2 sm:mt-0">{detail.value}</dd>
                                  </div>
                                )
                              })
                            }
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 border rounded border-[#222]">
                    <div className="bg-neutral-900 p-4 text-xl text-white font-semibold rounded">
                      - &nbsp;Terms
                    </div>
                    <div className="px-2 sm:px-5 py-2">
                      <div className="">
                        <dl className="divide-y divide-neutral-800">
                          {
                            memberTerms.map((detail) => {
                              return(
                                <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                  <dd className="space-y-1.5 sm:col-span-2">
                                    <div className="text-sm font-medium text-white">{detail.chamber} <span className="text-neutral-600 text-xs font-medium"> - {detail.member_type}</span></div>
                                    <div className="text-sm text-neutral-400">{detail.state_name}</div>
                                    <div className="text-xs italic font-medium text-neutral-500">{detail.start_year} - {detail.end_year}</div>
                                  </dd>
                                  <dt className="space-y-1.5">
                                    <div className="text-xs font-medium text-neutral-300"><span className="text-neutral-500 font-normal">Congress:</span> <span>{detail.congress}</span></div>
                                    <div className="text-xs font-medium text-neutral-300"><span className="text-neutral-500 font-normal">District:</span> <span>{detail.district}</span></div>
                                  </dt>
                                </div>
                              )
                            })
                          }
                        </dl>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 border rounded border-[#222]">
                    <div className="bg-neutral-900 p-4 text-xl text-white font-semibold rounded">
                      - &nbsp;Party History
                    </div>
                    <div className="px-2 sm:px-5 py-2">
                      <div className="">
                        <dl className="divide-y divide-neutral-800">
                          {
                            memberPartyHistory.map((detail) => {
                              return(
                                <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                  <dd className="text-xs text-white sm:col-span-2">{detail.party_name}</dd>
                                  <dt className="text-xs font-medium text-neutral-300">{detail.start_year}</dt>
                                </div>
                              )
                            })
                          }
                        </dl>
                      </div>
                    </div>
                  </div>
                  {/*                   
                  <div class="boxes grid grid-cols-2">
                    <div>
                      <div>
                        <div>
                            <h5>{member.tiktoklikes}</h5>
                            <h6>Likes</h6>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div>
                            <h5>{member.tiktokfollowers}</h5>
                            <h6>Followers</h6>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div>
                            <h5>{member.tiktokfollowing}</h5>
                            <h6>Following</h6>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div>
                            <h5><LuArrowUpRight className="mx-auto" size={26}/></h5>
                            <h6>Visit full profile</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex justify-center">
                      { sharedAlready == 1 || member.ifshared == 1 ? 
                      <button class="mx-auto btn-share flex items-center space-x-2">
                          <span>Shared with Team</span> <TbChecks size={16}/>
                      </button>
                      :
                      <button class="mx-auto btn-share flex items-center space-x-3" onClick={shareWithTeam}>
                          <FiUsers size={16}/> <span>Share profile with team</span>
                      </button>
                      }
                  </div>
                   */}
                  </div>
                  {/* <div class="mt-5 other-profiles">
                      <div>Explore their other profiles</div>
                      <div class="mt-3">
                        <div className="flex space-x-3 justify-center">
                          {member.tiktok != '' && <img src="/images/icons/tiktok.png"/>}
                          {member.instagram != '' && <img src="/images/icons/instagram.png"/>}
                          {member.youtube != '' && <img src="/images/icons/youtube.png"/>}
                          {member.twitch != '' && <img src="/images/icons/twitch.png"/>}
                        </div>
                      </div>
                  </div> */}
                  </div>
                  </>
                  }
                </div>
              </Modal>
              </>
            }
            <div className={`transition-all w-full`}>
              <div className="section-left flex flex-col">
                <div className="inf-filters">
                  <div className="flex space-x-5">
                    <div className="search flex-1">
                      {/* <img src="/images/icons/svgs/search-normal.svg" width="15" alt="Search"/> */}
                      <div className="absolute inset-y-0 start-0 flex items-center ps-4 pointer-events-none">
                      <FiSearch color="#999"/>
                      </div>
                      <input className="form-controlx filters-input" placeholder="Search..." value={searchTerm} onChange={(e) => handleSearch(e.target.value)} />
                    </div>
                  </div>
                  <div className="filters mt-6">
                    <div className="flex flex-wrap justify-start items-center filters-select">
                      <div className="select-group">
                        <button className="!rounded-[0.15rem]">
                          <PiSlidersHorizontal size={24} color={'#C2C2C2'}/>
                        </button>
                      </div>
                      <div className="select-group">
                        <button>Party</button>
                        <MultiSelect options={partyOptions} value={selectedPartyOptions} onChange={setSelectedPartyOptions} labelledBy="Party" />
                      </div>
                      <div className="select-group">
                        <button>State</button>
                        <MultiSelect options={stateOptions} value={selectedStateOptions} onChange={setSelectedStateOptions} labelledBy="State" />
                      </div>
                      <div className="select-group">
                        <button>Chamber</button>
                        <MultiSelect options={chamberOptions} value={selectedChamberOptions} onChange={setSelectedChamberOptions} labelledBy="Chamber" />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-wrap justify-start items-center">
                    { selectedOptions.length > 0 && 
                    <div className="mr-4 mt-[10px]">
                      <PiSlidersHorizontalFill size={24} color={'#C2C2C2'}/>
                    </div>
                    }
                    <div className="selected-tags">
                      {selectedOptions.map(option => (
                        <div key={option} className="tag">
                          {option}
                          <span className="remove" onClick={() => setSelectedOptions(selectedOptions.filter(o => o !== option))}>
                            x
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {/* <div className="flex flex-col flex-1">  */}
                      
                {fetchingLoading ? (
                  <div className="w-full py-10">
                    <Spinner color="#CCCCCC" className="mx-auto" size={24} />
                  </div>
                )
                : 
                <>
                  { members.length > 0 ? 
                    <>
                    {/* <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}> */}
                      <div className="members-records grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-5 sm:gap-10 pt-2" onScroll={handleScroll}> 
                        { members.map((member, index) => {
                            return(
                              <>
                              <div className="member-card" key={index}>
                                  <div className="absolute right-0 top-0 only-hover">
                                    <div className="flex flex-end">
                                      <div className="relative">
                                        <div class={`btn-save-to-list flex items-center space-x-1 ${showListsDropdown == member.bioguide_id && '!rounded-b-none'}`} onClick={() => setShowListsDropdown((showListsDropdown == member.bioguide_id ? '' : member.bioguide_id))}>
                                            <span>Save to list</span> <HiMiniChevronDown size={16}/>
                                        </div>
                                        { showListsDropdown == member.bioguide_id && 
                                        <div className="save-list-dropdown">
                                          {
                                          lists.map((list, index) => {
                                            return(
                                              <div className={`dropdown-item`} onClick={() => saveToList(member.bioguide_id, list.listid)}>{list.name}</div>
                                            )
                                          })
                                          }
                                        </div> 
                                        }
                                      </div>
                                    </div>
                                  </div>
                                  <Link to={`/member/${member.bioguide_id}`} target="_blank" className="member-card-content">
                                    <div className="flex space-x-4">
                                      <img className="rounded-lg object-cover h-24 w-24" src={member.image_url}/>
                                      <div className="flex-1">
                                        <div className="flex space-x-2">
                                          <button className={`text-[10px] font-medium p-0.5 px-1 rounded ${member.party_name == 'Republican' ? 'bg-red-100 text-red-900' : 'bg-blue-100 text-blue-900'}`}>{member.party_name}</button>
                                          <button className={`text-[10px] font-normal text-neutral-300 p-0.5 px-1 rounded bg-[#444]`}>{member.state}</button>
                                        </div>
                                        <h3 className="text-xl font-bold text-white my-1">{member.name}</h3>
                                        {/* <h6 className="text-sm font-medium text-neutral-400">{member.state}</h6> */}
                                        <h6 className="text-sm font-medium text-neutral-600">{member.start_year} - {(member.end_year == null ? 'Present' : member.end_year)}</h6>
                                        <h6 className="text-sm mt-0.5 text-neutral-700">{member.chamber}</h6>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              </>
                            )
                          })
                        } 
                      </div>
                      {loadingMore && (
                        <div className="w-full py-5">
                          <Spinner color="#CCCCCC" className="mx-auto" size={20} />
                        </div>
                      )}
                    {/* </motion.div> */}
                    </>
                    :
                    <div className="text-center w-full text-neutral-500 font-medium mt-16">no records found</div>
                  }
                </>
                }
              </div>
            </div>
          </div>
        )}
      </section>
    </AppLayout>
  );
};

export default Discover;
