import AppLayout from "../layouts/AppLayout";
import React, { useContext, useState, useEffect, useCallback } from "react";
import { AppContext } from "../contexts/AppContext";
import { debounce } from "lodash";
import { Link, useNavigate, useParams } from "react-router-dom";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import "../styles/lists.scss";
import Swal from "sweetalert2";
import Tooltip from "../components/Tooltip";
import { FiUsers } from "react-icons/fi";
import { FiEdit3 } from "react-icons/fi";
import { TbSquareMinusFilled } from "react-icons/tb";
import Modal from "../components/Modal";
import { format } from "date-fns";
import { FiMinus } from "react-icons/fi";

const List = () => {
  const { listId } = useParams();

  const navigate = useNavigate();

  const [list, setList] = useState("");
  const [fetchingLoading, setfetchingLoading] = useState(true);
  const [memberIds, setMemberIds] = useState([]);
  const [member, setMember] = useState("");
  const [memberTerms, setMemberTerms] = useState();
  const [memberPartyHistory, setMemberPartyHistory] = useState();
  const [memberId, setMemberId] = useState("");
  const [toDelete, settoDelete] = useState('');
  const [editing, setEditing] = useState(false);
  const [memberLoading, setmemberLoading] = useState(false);

  const { getlistmembers, getmember, updatememberlistlistid, getlist, removefromlist, bulkremovefromlist, deletemylist, isLoading } = useContext(AppContext);

  const [listLists, setListLists] = useState([]);
  const [members, setMembers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [offset, setOffset] = useState(0);

  const memberdetails = [
    { label: 'Full Name', value: ((member.honorific_name != "" && member.honorific_name != "null" && member.honorific_name != null) ? member.honorific_name+" " : "") + member.first_name + " " + member.last_name },
    { label: 'State', value: member.state },
    { label: 'District', value: member.district },
    { label: 'Party', value: member.party_name },
    { label: 'Chamber', value: member.chamber },
    { label: 'Career', value: member.start_year+" - "+member.end_year },
  ];

  const memberdetails2 = [
    { label: 'Birth Year', value: member.birth_year },
    { label: 'Website', value: member.official_website_url },
    { label: 'Sponsored Legislative', value: member.sponsored_legislation_count },
    { label: 'Cosponsored Legislative', value: member.cosponsored_legislation_count },
    { label: 'Current Member', value: member.current_member },
    { label: 'Last Updated', value: member.update_date ? format(new Date(member.update_date.replace(" ", "T")), "do MMM yyyy") : '' },
  ];

  const limit = 24;

  const fetchList = async () => {
    const response = await getlist(listId);
    if (response) {
      if (response[0] != "") {
        setList(response[0]);
      } else {
        navigate("/lists");
      }
    } else {
      navigate("/lists");
    }
  }


  const fetchMembers = useCallback(async () => {
    if (!hasMore) return;
    setLoadingMore(true);
    const response = await getlistmembers(searchTerm, listId, limit, offset);
    if (response.length > 0) {
      setMembers((prev) => [...prev, ...response]);
      setOffset((prev) => prev + limit);
    } else {
      setHasMore(false);
    }
    setfetchingLoading(false);
    setLoadingMore(false);
  }, [getlistmembers, searchTerm, offset, hasMore]);

  const debouncedFetchMembers = useCallback(debounce(fetchMembers, 300), [searchTerm]);

  useEffect(() => {
    debouncedFetchMembers();
  }, [debouncedFetchMembers]);

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && !loadingMore) {
      fetchMembers();
    }
  };

  useEffect(() => {
    if (listId) {
      fetchList();
    }
  }, []);

  const moveInfluencer = async (memberid, oldlistid, direction) => {
    const currentListIndex = listLists.findIndex(list => list.listslistid === oldlistid);
    const newListIndex = direction === 'right' ? currentListIndex + 1 : currentListIndex - 1;
  
    if (newListIndex < 0 || newListIndex >= listLists.length) return; // Disable if out of bounds
  
    const newlistid = listLists[newListIndex].listslistid;

    const response = await updatememberlistlistid(memberid, listId, oldlistid, newlistid);
    if (response && response === "success") {
  
      setListLists(prevLists => {
        const updatedLists = [...prevLists];
        const oldList = updatedLists[currentListIndex];
        const newList = updatedLists[newListIndex];
    
        const member = oldList.members.find(inf => inf.memberid === memberid);
        if (!member) return updatedLists; // If member not found, return current state
    
        oldList.members = oldList.members.filter(inf => inf.memberid !== memberid);
        newList.members = newList.members.filter(inf => inf !== null); // Ensure no null values
        newList.members = [...newList.members, member];
    
        return updatedLists;
      });

    }
  };

  const handleDeleteList = async () => {
    if (!isLoading) {
      try {
        const result = await Swal.fire({
          title: 'Are you sure to delete the entire list?',
          text: "You won't be able to undo this action",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Delete'
        });
  
        if (result.isConfirmed) {
          const response = await deletemylist(listId);
          if (response === 'success') {
            navigate('/lists');
          }
        }
      } catch (error) {
        Swal.fire("An Error Occurred", error, "error");
      }
    }
  };

  const handleBulkDelete = async () => {
    if (!isLoading) {
      try {
        const result = await Swal.fire({
          title: 'Are you sure to remove?',
          text: "You won't be able to undo this action",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Remove'
        });
  
        if (result.isConfirmed) {
          const response = await bulkremovefromlist(memberIds, listId);
          if (response === 'success') {
            setMembers(members.filter(member => !memberIds.includes(member.bioguide_id)));
            setMemberIds([]);
          }
        }
      } catch (error) {
        Swal.fire("An Error Occurred", error, "error");
      }
    }
  };

  const handleDelete = async (todelete) => {
    if (!isLoading) {
      try {
        const result = await Swal.fire({
          title: 'Are you sure to remove?',
          text: "You won't be able to undo this action",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Remove'
        });
  
        if (result.isConfirmed) {
          const response = await removefromlist(todelete, listId);
          if (response === 'success') {
            setMembers(members.filter(member => !todelete.includes(member.bioguide_id)));
          }
        }
      } catch (error) {
        Swal.fire("An Error Occurred", error, "error");
      }
    }
  };
  
  const handleCheckboxChange = (memberId) => {
    setMemberIds((prev) =>
      prev.includes(memberId)
        ? prev.filter((id) => id !== memberId)
        : [...prev, memberId]
    );
  };
  
  const handleSelectAll = () => {
    if (memberIds.length === members.length) {
      setMemberIds([]); // Deselect all
    } else {
      setMemberIds(members.map((inf) => inf.memberid)); // Select all
    }
  };

  useEffect(() => {
    setMember([]);
    const fetchMember = async () => {
      if(memberId != ''){
        setmemberLoading(true);
        const response = await getmember(memberId);
        setMember(response.basic[0]);
        setMemberTerms(response.terms);
        setMemberPartyHistory(response.partyHistory);
        setmemberLoading(false);
      }
    };

    fetchMember();
  }, [memberId]);
  

    // Update in the backend
    // const response = await updateInfluencerList(memberid, oldlistid, newlistid);
    // if (response && response.message === "success") {
    //   // Update state
    //   setListLists(prevLists => {
    //     const updatedLists = [...prevLists];
    //     const oldList = updatedLists[currentListIndex];
    //     const newList = updatedLists[newListIndex];
  
    //     const member = oldList.members.find(inf => inf.memberid === memberid);
    //     oldList.members = oldList.members.filter(inf => inf.memberid !== memberid);
    //     newList.members = [...newList.members, member];
  
    //     return updatedLists;
    //   });
    // }
  // };  

  return (
    <AppLayout pageId="list-details" pageName="List Details">
      {list ? (
        <>
          <section className="section-left">
            <div className="flex flex-row justify-between items-center space-y-5 sm:space-y-0 px-5 sm:px-8 py-5 sm:py-6 bg-main-alt border border-[#222] rounded-lg">
              <div>
                {/* <div><Link to={'/lists'}><button className="btn-main-alt flex items-center !text-xs !w-auto !px-5"><GoArrowLeft size={16} className="mr-1.5"/> <span>Back to all lists</span></button></Link></div> */}
                <div className="mb-3 text-2xl md:text-4xl text-main font-semibold">
                    <h4>{list.name}</h4>
                </div>
                <div>
                  <div className="flex space-x-5">
                    <div>
                      <div className="text-neutral-500 font-medium flex space-x-2 items-center text-sm"><FiUsers /> <span>{members.length} Members</span></div>
                    </div>
                    {/* <div>
                      <button className="btn-main-alt !text-sm">Start {list.startdate}</button>
                    </div>
                    <div>
                      <button className="btn-main-alt !text-sm">End {list.enddate}</button>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="flex space-x-5 list-options">
                { editing ? 
                <>
                {memberIds.length > 0 && 
                  <div><button className="btn-delete !px-5" onClick={() => handleBulkDelete()}>Delete Selected</button></div>
                }
                { editing && 
                  <div><button className="btn-delete-inv !px-5" onClick={(e) => handleDeleteList(e)}>Delete List</button></div> 
                }
                  <div><button className="btn-submit !px-8" onClick={() => setEditing(false)}>Done</button></div>
                </>
                : 
                <>
                <div><button className="btn-edit flex items-center" onClick={() => setEditing(true)}><FiEdit3 size={16} className="mr-1.5"/> <span>Edit List</span></button></div>
                </>
                }
              </div>
            </div>
            {fetchingLoading ? (
              <div className="w-full py-10">
                <Spinner color="#CCCCCC" className="mx-auto" size={24} />
              </div>
            )
            : 
            <>
            
            { (memberId != '' && member != '') && 
              <>
              <Modal heading="Member Info" isOpen={"true"} onClose={() => setMemberId(false)}>
                <div class="member-modal">
                  { memberLoading ? <div className="flex justify-center w-full py-12"> <Spinner color="#CCCCCC" size={24} /> </div> : 
                  <>
                  <div className="w-full flex flex-1 flex-col justify-between">
                  <div>
                  <div className="flex flex-col sm:flex-row sm:space-x-5">
                    <div>
                    <img className={`rounded-lg border-2 mx-auto ${member.party_name == 'Republican' ? 'border-red-500' : 'border-blue-500'}`} src={member.image_url}/>
                      <div>
                        <div className="flex justify-center mt-3">
                          <div class={`btn-delete-inv !text-sm !py-1 !font-medium mx-auto flex items-center justify-between space-x-2`} onClick={() => handleDelete(memberId)}>
                          <FiMinus size={16}/> <span>Remove from List</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex-1">
                    <div className="grid grid-cols-1 md:grid-cols-2 sm:gap-10 mt-5 sm:mt-0">
                        <div className="">
                          <dl className="divide-y divide-neutral-800">
                            {
                              memberdetails.map((detail) => {
                                return(
                                  <div className="px-0 py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                                    <dt className="text-sm text-neutral-500">{detail.label}</dt>
                                    <dd className="mt-1 text-sm font-medium text-white sm:col-span-2 sm:mt-0">{detail.value}</dd>
                                  </div>
                                )
                              })
                            }
                          </dl>
                        </div>
                        
                        <div className="border-t border-neutral-800 sm:border-t-0">
                          <dl className="divide-y divide-neutral-800">
                            {
                              memberdetails2.map((detail) => {
                                return(
                                  <div className="px-0 py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                                    <dt className="text-sm text-neutral-500">{detail.label}</dt>
                                    <dd className="mt-1 text-sm font-medium text-white sm:col-span-2 sm:mt-0">{detail.value}</dd>
                                  </div>
                                )
                              })
                            }
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 border rounded border-[#222]">
                    <div className="bg-neutral-900 p-4 text-xl text-white font-semibold rounded">
                      - &nbsp;Terms
                    </div>
                    <div className="px-2 sm:px-5 py-2">
                      <div className="">
                        <dl className="divide-y divide-neutral-800">
                          {
                            memberTerms.map((detail) => {
                              return(
                                <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                  <dd className="space-y-1.5 sm:col-span-2">
                                    <div className="text-sm font-medium text-white">{detail.chamber} <span className="text-neutral-600 text-xs font-medium"> - {detail.member_type}</span></div>
                                    <div className="text-sm text-neutral-400">{detail.state_name}</div>
                                    <div className="text-xs italic font-medium text-neutral-500">{detail.start_year} - {detail.end_year}</div>
                                  </dd>
                                  <dt className="space-y-1.5">
                                    <div className="text-xs font-medium text-neutral-300"><span className="text-neutral-500 font-normal">Congress:</span> <span>{detail.congress}</span></div>
                                    <div className="text-xs font-medium text-neutral-300"><span className="text-neutral-500 font-normal">District:</span> <span>{detail.district}</span></div>
                                  </dt>
                                </div>
                              )
                            })
                          }
                        </dl>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 border rounded border-[#222]">
                    <div className="bg-neutral-900 p-4 text-xl text-white font-semibold rounded">
                      - &nbsp;Party History
                    </div>
                    <div className="px-2 sm:px-5 py-2">
                      <div className="">
                        <dl className="divide-y divide-neutral-800">
                          {
                            memberPartyHistory.map((detail) => {
                              return(
                                <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                  <dd className="text-xs text-white sm:col-span-2">{detail.party_name}</dd>
                                  <dt className="text-xs font-medium text-neutral-300">{detail.start_year}</dt>
                                </div>
                              )
                            })
                          }
                        </dl>
                      </div>
                    </div>
                  </div>
                  </div>
                  </div>
                  </>
                  }
                </div>
              </Modal>
              </>
            }
            { members.length > 0 ? 
              <>
              <div className="members-records grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-5 sm:gap-10 pt-5" onScroll={handleScroll}> 
                { members.map((member, index) => {
                    return(
                      <>
                      <div className="member-card" key={index}>
                        <div className={`hidden sm:block absolute -right-1 -top-1 ${!editing && 'only-hover'}`}>
                          <div className="flex flex-end">
                            <div className="relativex">
                              { editing ? <input type={'checkbox'} className="w-5 h-5 border-neutral-800 rounded" onChange={(e) => { e.preventDefault(); handleCheckboxChange(member.bioguide_id); }} checked={memberIds.includes(member.bioguide_id)}/> :
                              <button class={`btn-remove`} onClick={(e) => { e.preventDefault(); handleDelete(member.bioguide_id); }}>
                                  <TbSquareMinusFilled className="text-red-500 hover:text-red-600" size={26}/>
                              </button>
                              }
                            </div>
                          </div>
                        </div>
                        <Link to={`/member/${member.bioguide_id}`} target="_blank" className="member-card-content">
                          <div className="flex space-x-4">
                            <img className="rounded-lg object-cover h-24 w-24" src={member.image_url}/>
                            <div className="flex-1">
                              <div className="flex space-x-2">
                                <button className={`text-[10px] font-medium p-0.5 px-1 rounded ${member.party_name == 'Republican' ? 'bg-red-100 text-red-900' : 'bg-blue-100 text-blue-900'}`}>{member.party_name}</button>
                                <button className={`text-[10px] font-normal text-neutral-300 p-0.5 px-1 rounded bg-[#444]`}>{member.state}</button>
                              </div>
                              <h3 className="text-xl font-bold text-white my-1">{member.name}</h3>
                              {/* <h6 className="text-sm font-medium text-neutral-400">{member.state}</h6> */}
                              <h6 className="text-sm font-medium text-neutral-600">{member.start_year} - {(member.end_year == null ? 'Present' : member.end_year)}</h6>
                              <h6 className="text-sm mt-0.5 text-neutral-700">{member.chamber}</h6>
                            </div>
                          </div>
                        </Link>
                      </div>
                      </>
                    )
                  })
                } 
              </div>
              {loadingMore && (
                <div className="text-center py-10">
                  <Spinner color="#CCCCCC" size={24} />
                </div>
              )}
              </>
              :
              <div className="text-center w-100 text-neutral-500 font-medium mt-16">no members found</div>
            }
            </>
            }
          </section>
        </>
      ) : (
        <div className="flex justify-center w-full py-12">
          <Spinner color="#CCCCCC" size={24} />
        </div>
      )}
    </AppLayout>
  );
};

export default List;
