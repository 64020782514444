import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import AuthLayout from "../layouts/AuthLayout";
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";
import { IoMailOutline } from "react-icons/io5";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [mailsent, setMailsent] = useState(false);
  const [error, setError] = useState("");

  const { forgotpassword, isLoading } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading === false) {
      try {
        if (email !== "") {
          setError("");
          const response = await forgotpassword(email);
          if (response === "success") {
            setMailsent(true);
          }
        } else {
          setError("All fields are required");
          return;
        }
      } catch (error) {
        setError("failed: " + error);
      }
    }
  };

  return (
    <AuthLayout page="Forgot Password" heading={mailsent ? "Reset link sent!" : "Reset your password"} subheading={mailsent ? `Check your <span>${email}</span> inbox for instructions from us on how to reset your password.` : 'To reset your password, enter the email address you use to sign in.'}>
      {mailsent === false ? (
        <>
          <form onSubmit={handleSubmit} className="login-form">
            <div>
              <div className="form-group email">
                <label htmlFor="email">Email Address</label>
                <div class="input-icon">
                  <IoMailOutline />
                </div>
                <input id="email" name="email" required type="email" className="form-control mt-1" placeholder="hello@policyportal.com" onChange={(e) => setEmail(e.target.value)} value={email} />
              </div>
              {error !== "" ? <div className="text-center text-red-500 font-semibold text-md my-3">{error}</div> : <div className="py-4"></div>}
              <button type="submit" className="w-full btn btn-main-auth" disabled={isLoading}>
                {isLoading === false ? "Get a Reset Link" : <Bounce animating={isLoading} />}
              </button>
              <div className="mt-5 text-neutral-500 text-sm text-center font-medium">
                Already know the password? <Link className="text-main font-medium" to="/">Sign in instead</Link>
              </div>
            </div>
          </form>
        </>
      ) : (
        <>
          <div>
            <h5 className="subheading text-center">Wrong email address?<br/>Please <span onClick={() => setMailsent(false)} className="cursor-pointer text-blue-500 font-semibold"> re-enter email address. </span></h5>
            <div className="mt-14 flex flex-row justify-center space-x-16">
              <button className="flex space-x-3 text-black font-medium">
                <img src="/images/icons/gmail.png"/> <span>Open Gmail</span>
              </button>
              <button className="flex space-x-3 text-black font-medium">
                <img src="/images/icons/outlook.png"/> <span>Open Outlook</span>
              </button>
            </div>
          </div>
        </>
      )}
    </AuthLayout>
  );
};

export default ForgotPassword;
