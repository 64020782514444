import React, { useContext, useState, useEffect, useCallback } from "react";
import { debounce } from "lodash";
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";
import AppLayout from "../layouts/AppLayout";
import { AppContext } from "../contexts/AppContext";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import "../styles/lists.scss";
import Modal from "../components/Modal";
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa";

const Lists = () => {
  const { addnewlist, getlists, getlistinfluencers, getinfluencer, sharewithteam, saveit, unsaveit, savetolist, removefromlist, bulkremovefromlist, userToken, isLoading } = useContext(AppContext);

  const navigate = useNavigate();

  const [influencers, setInfluencers] = useState([]);
  const [lists, setLists] = useState([]);
  const [dropdownlists, setdropdownLists] = useState([]);
  const [influencer, setInfluencer] = useState("");
  const [listId, setListId] = useState("");
  const [selectedListName, setSelectedListName] = useState("");
  const [infId, setInfId] = useState("");
  const [infIds, setInfIds] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [infLoading, setinfLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [showNewModal, setshowNewModal] = useState(false);
  const [listName, setListName] = useState('');
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [showListsDropdown, setShowListsDropdown] = useState(false);

  const limit = 10;

  const fetchInfluencers = useCallback(async (listId) => {
    if (listId != '') {
      if (!hasMore) return;
      setLoadingMore(true);
      const response = await getlistinfluencers(searchTerm, listId, limit, offset);
      if (response.length > 0) {
        if(influencers.length == 0){
          setInfluencers(response);
        }
        else{
          setInfluencers((prev) => [...prev, ...response]);
        }
        setOffset((prev) => prev + limit);
      } else {
        setHasMore(false);
      }
      setLoadingMore(false);
    }
  }, [getlistinfluencers, searchTerm, offset, hasMore]);

  const debouncedFetchInfluencers = useCallback(debounce(fetchInfluencers, 300), [searchTerm]);

  useEffect(() => {
    fetchInfluencers(listId); // Pass the current listId here
  }, [listId, debouncedFetchInfluencers]);

  const handleSearch = (searchValue) => {
    setSearchTerm(searchValue);
    setInfluencers([]);
    setOffset(0);
    setHasMore(true);
    fetchInfluencers(listId); // Pass the current listId here
  };

  const handleListChange = (newListId, name) => {
    setListId(newListId);
    setSelectedListName(name);
    setInfluencers([]);
    setOffset(0);
    setHasMore(true);
    fetchInfluencers(newListId);
    setInfId('');
    setShowListsDropdown(false);
  };

  const handleRemove = (value) => {
    setSelectedOptions(selectedOptions.filter(option => option !== value));
  };

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && !loadingMore) {
      fetchInfluencers(listId);
    }
  };

  useEffect(() => {
    if (listName !== "") {
      setIsFormChanged(true);
    } else {
      setIsFormChanged(false);
    }
  }, [listName]);

  const fetchLists = async () => {
    const response = await getlists();
    setLists(response);
    setdropdownLists(response);
  };

  useEffect(() => {
    fetchLists();
  }, []);

  const handleAddNewList = async (e) => {
    setFormLoading(true);
    e.preventDefault();
    await addnewlist(listName);
    setshowNewModal(false);
    setListName('');
    setFormLoading(false);
    fetchLists();
  };

  const handleDelete = async (todelete) => {
    if (!isLoading) {
      try {
        const result = await Swal.fire({
          title: 'Are you sure to remove?',
          text: "You won't be able to undo this action",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Remove'
        });
  
        if (result.isConfirmed) {
          const response = await removefromlist(todelete, listId);
          if (response === 'success') {
            setInfluencers(influencers.filter(influencer => influencer.influencerid !== todelete));
          }
        }
      } catch (error) {
        Swal.fire("An Error Occurred", error, "error");
      }
    }
  };
  

  return (
    <AppLayout pageId="lists" pageName={"Lists"} extraHtml={ showNewModal && 
      <Modal heading="New List" isOpen={showNewModal} onClose={() => setshowNewModal(false)}>
        <form onSubmit={handleAddNewList}>
          <div className="modal-body">
            <div className="mb-8">
              <label>List Name</label>
              <input className="form-control" type="text" maxLength={30} value={listName} onChange={(e) => setListName(e.target.value)} placeholder="" autoFocus required/>
            </div>
          </div>
          <div className="modal-footer">
            <button type="submit" className="btn-submit" disabled={formLoading || !isFormChanged}>
              {formLoading === false ? "Submit" : <Bounce className="my-2" animating={formLoading} />}
            </button>
          </div>
        </form>
      </Modal>
      }>
      <section>
        {isLoading ? (
          <div className="flex justify-center w-full py-12">
            <Spinner color="#CCCCCC" size={24} />
          </div>
        ) : (
          <div className="section-left flex flex-col">
            <div className="flex justify-between">
              <div>
                <button className={`btn-new flex items-center`} onClick={() => setshowNewModal(true)}><FaPlus size={16} className="mr-1.5"/> New list</button>
              </div>
              <div className="page-head">
                {/* <h4 className="text-lg text-main font-semibold">{lists.length + 2} lists</h4> */}
              </div>
            </div>
            <div className="flex flex-col space-y-3 mt-5 lists-wrapper">
              {/* <div className={`btn-list-item-wrapper ${listId === userToken.userid && 'active'}`} onClick={() => { if(listId !== userToken.userid){ handleListChange(userToken.userid, 'Saved') } }}><button className={`btn-list-item ${listId === userToken.userid && 'active'}`}>Saved</button></div>
              <div className={`btn-list-item-wrapper ${listId === userToken.teamid && 'active'}`} onClick={() => { if(listId !== userToken.teamid){ handleListChange(userToken.teamid, 'Shared with Team') } }}><button className={`btn-list-item ${listId === userToken.teamid && 'active'}`}>Shared with Team</button></div> */}
              { lists.map((list, index) => {
                return(
                  <div className="list-card" onClick={() => navigate(`/lists/${list.listid}`)} key={index}>
                    <h5 className="text-2xl text-main font-medium">{list.name}</h5>
                    <div className="grid grid-cols-2 sm:grid-cols-3 mt-3 text-sm">
                      <div>
                        <div><span className="text-neutral-600">Members:</span> <span className="font-semibold">{list.totalmembers}</span></div>
                      </div>
                      <div>
                        <div><span className="text-neutral-600">Created:</span> <span className="font-medium">{list.addedatdate}</span></div>
                      </div>
                    </div>
                  </div>
                  // <div className={`btn-list-item-wrapper ${listId === list.listid && 'active'}`} onClick={() => { if(listId !== list.listid){ handleListChange(list.listid, list.name) } }}><button className={`btn-list-item ${listId === list.listid && 'active'}`}>{list.name}</button></div>
                )
              }) }
            </div>
          </div>
        )}
      </section>
    </AppLayout>
  );
};

export default Lists;
