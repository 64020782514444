import "./App.css";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Register from "./pages/Register";
import ForgotPassword from "./pages/ForgotPassword";
import Dashboard from "./pages/Dashboard";
import AddClient from "./pages/AddClient";
import AllClients from "./pages/AllClients";
import MyProfile from "./pages/MyProfile";
import PrivateRoute from "./components/PrivateRoute";
import ChangePassword from "./pages/ChangePassword";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import './styles/fonts.scss';
import Discover from "./pages/Discover";
import GoogleAuthSuccess from "./components/GoogleAuthSuccess";
import Lists from "./pages/Lists";
import AllListings from "./pages/AllListings";
import Member from "./pages/Member";
import List from "./pages/List";
import { AnimatePresence } from "framer-motion";

function App() {
  return (
    <AnimatePresence>
      <Router>
        <Routes>
          <Route path="/" index element={<Home />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />

          <Route path="/google-success" element={<GoogleAuthSuccess />} />

          <Route path="/dashboard" element={ <PrivateRoute> <Dashboard /> </PrivateRoute> } />
          <Route path="/discover" element={ <PrivateRoute> <Discover /> </PrivateRoute> } />
          <Route path="/lists" element={ <PrivateRoute> <Lists /> </PrivateRoute> } />
          <Route path="/lists/:listId" element={ <PrivateRoute> <List /> </PrivateRoute> } />
          
          <Route path="/all-listings" element={<PrivateRoute allowedto={["Admin"]}><AllListings /></PrivateRoute>} />

          <Route path="/member/:memberId" element={<Member />} />

          {/* <Route path="/" element={ <PrivateRoute> <Discover /> </PrivateRoute> } /> */}
          <Route path="/profile" element={<PrivateRoute><MyProfile /></PrivateRoute>} />
          <Route path="/password" element={<PrivateRoute><ChangePassword /></PrivateRoute>} />
          <Route path="/add-client" element={<PrivateRoute allowedto={["Admin"]}><AddClient /></PrivateRoute>} />
          <Route path="/add-client/:userId" element={<PrivateRoute allowedto={["Admin"]}><AddClient /></PrivateRoute>} />
          <Route path="/all-clients" element={<PrivateRoute allowedto={["Admin"]}><AllClients /></PrivateRoute>} />

        </Routes>
      </Router>
    </AnimatePresence>
  );
}

export default App;
