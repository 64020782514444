import React, { Fragment, useContext, useState, useEffect } from "react";
import AppLayout from "../layouts/AppLayout";
import DataTable from "../components/DataTable";
import { AppContext } from "../contexts/AppContext";
import { Link } from "react-router-dom";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import Swal from "sweetalert2";
import { PiSlidersHorizontal } from "react-icons/pi";
import { MultiSelect } from "react-multi-select-component";
import { motion } from "framer-motion";
import Modal from "../components/Modal";
import { format } from "date-fns";

const AllListings = () => {
  const { getlistings, getdiscoveroptions, getmember, deletelisting, isLoading } = useContext(AppContext);

  const [listings, setListings] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const [memberId, setMemberId] = useState("");
  const [member, setMember] = useState("");
  const [memberTerms, setMemberTerms] = useState();
  const [memberPartyHistory, setMemberPartyHistory] = useState();
  const [memberLoading, setmemberLoading] = useState(false);
  const [partyOptions, setPartyOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [chamberOptions, setChamberOptions] = useState([]);
  const [selectedPartyOptions, setSelectedPartyOptions] = useState([]);
  const [selectedStateOptions, setSelectedStateOptions] = useState([]);
  const [selectedChamberOptions, setSelectedChamberOptions] = useState([]);

  const memberdetails = [
    { label: 'Full Name', value: ((member.honorific_name != "" && member.honorific_name != "null" && member.honorific_name != null) ? member.honorific_name+" " : "") + member.first_name + " " + member.last_name },
    { label: 'State', value: member.state },
    { label: 'District', value: member.district },
    { label: 'Party', value: member.party_name },
    { label: 'Chamber', value: member.chamber },
    { label: 'Career', value: member.start_year+" - "+member.end_year },
  ];

  const memberdetails2 = [
    { label: 'Birth Year', value: member.birth_year },
    { label: 'Website', value: member.official_website_url },
    { label: 'Sponsored Legislative', value: member.sponsored_legislation_count },
    { label: 'Cosponsored Legislative', value: member.cosponsored_legislation_count },
    { label: 'Current Member', value: member.current_member },
    { label: 'Last Updated', value: member.update_date ? format(new Date(member.update_date.replace(" ", "T")), "do MMM yyyy") : '' },
  ];

  const handleDelete = async (todelete) => {
    if (!isLoading) {
      try {
        const result = await Swal.fire({
          title: "Are you sure to delete?",
          text: "You won't be able to undo this action",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Delete",
        });

        if (result.isConfirmed) {
          let response = await deletelisting(todelete);
          if (response === "success") {
            fetchListings();
          }
        }
      } catch (error) {
        Swal.fire("An Error Occurred", error, "error");
      }
    }
  };

  const columns = React.useMemo(
    () => [
      { Header: "Name", accessor: "name" },
      { Header: "State", accessor: "state" },
      { Header: "District", accessor: "district" },
      { Header: "Party Name", accessor: "party_name" },
      { Header: "Chamber", accessor: "chamber" },
      {
        Header: "Year",
        accessor: "start_year",
        Cell: ({ row }) => (
          <div>
            {row.original.start_year+' - '+(row.original.end_year != null ? row.original.end_year : 'Present')}
          </div>
        ),
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div>
            <Link className="text-edit" to={`/add-listing/${row.original.bioguide_id}`} onClick={(e) => e.stopPropagation()}>
              Edit
            </Link>
            &nbsp;
            <button className="text-delete" onClick={(e) => {e.stopPropagation(); handleDelete(row.original.bioguide_id); }}>
              Delete
            </button>
          </div>
        ),
      },
      { Header: "Member ID", accessor: "bioguide_id" },
    ],
    []
  );

  const fetchListings = async () => {

    const partyValues = selectedPartyOptions.map(option => option.value);
    const stateValues = selectedStateOptions.map(option => option.value);
    const chamberValues = selectedChamberOptions.map(option => option.value);

    const response = await getlistings(statusFilter, partyValues, stateValues, chamberValues);
    setListings(prev => {
          const allMembers = [...prev, ...response];
          // Remove duplicates using bioguide_id
          return Array.from(new Map(allMembers.map(m => [m.bioguide_id, m])).values());
      });
  };

  const fetchOptions = async () => {
    try {
      const response = await getdiscoveroptions();
      if (response) {
        setPartyOptions(response.party);
        setStateOptions(response.state);
        setChamberOptions(response.chamber);
      }
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  const handleRowClick = (rowData) => {
    const memberId = rowData.bioguide_id;
    setMemberId(memberId);
  };

  useEffect(() => {
    setMember([]);
    const fetchMember = async () => {
      if(memberId != ''){
        setmemberLoading(true);
        const response = await getmember(memberId);
        setMember(response.basic[0]);
        setMemberTerms(response.terms);
        setMemberPartyHistory(response.partyHistory);
        setmemberLoading(false);
      }
    };

    fetchMember();
  }, [memberId]);

  useEffect(() => {
    setListings([]);
    fetchListings();
  }, [selectedPartyOptions, selectedStateOptions, selectedChamberOptions]);

  useEffect(() => {
    fetchListings();
    fetchOptions();
  }, []);

  return (
    <AppLayout pageName="All Listings">
      <section>
        {isLoading ? (
          <div className="flex justify-center w-full py-12">
            <Spinner color="#CCCCCC" size={24} />
          </div>
        ) : (
          <>
          
            { (memberId != '' && member != '') && 
              <>
              <Modal heading="Member Info" isOpen={"true"} onClose={() => setMemberId(false)}>
                <div class="member-modal">
                  { memberLoading ? <div className="flex justify-center w-full py-12"> <Spinner color="#CCCCCC" size={24} /> </div> : 
                  <>
                  <div className="w-full flex flex-1 flex-col justify-between">
                  <div>
                  <div className="flex flex-col sm:flex-row sm:space-x-5">
                    <div>
                      <img className={`rounded-lg border-2 mx-auto ${member.party_name == 'Republican' ? 'border-red-500' : 'border-blue-500'}`} src={member.image_url}/>
                    </div>
                    <div className="flex-1">
                      <div className="grid grid-cols-1 md:grid-cols-2 sm:gap-10 mt-5 sm:mt-0">
                        <div className="">
                          <dl className="divide-y divide-neutral-800">
                            {
                              memberdetails.map((detail) => {
                                return(
                                  <div className="px-0 py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                                    <dt className="text-sm text-neutral-500">{detail.label}</dt>
                                    <dd className="mt-1 text-sm font-medium text-white sm:col-span-2 sm:mt-0">{detail.value}</dd>
                                  </div>
                                )
                              })
                            }
                          </dl>
                        </div>
                        
                        <div className="border-t border-neutral-800 sm:border-t-0">
                          <dl className="divide-y divide-neutral-800">
                            {
                              memberdetails2.map((detail) => {
                                return(
                                  <div className="px-0 py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                                    <dt className="text-sm text-neutral-500">{detail.label}</dt>
                                    <dd className="mt-1 text-sm font-medium text-white sm:col-span-2 sm:mt-0">{detail.value}</dd>
                                  </div>
                                )
                              })
                            }
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 border rounded border-[#222]">
                    <div className="bg-neutral-900 p-4 text-xl text-white font-semibold rounded">
                      - &nbsp;Terms
                    </div>
                    <div className="px-2 sm:px-5 py-2">
                      <div className="">
                        <dl className="divide-y divide-neutral-800">
                          {
                            memberTerms.map((detail) => {
                              return(
                                <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                  <dd className="space-y-1.5 sm:col-span-2">
                                    <div className="text-sm font-medium text-white">{detail.chamber} <span className="text-neutral-600 text-xs font-medium"> - {detail.member_type}</span></div>
                                    <div className="text-sm text-neutral-400">{detail.state_name}</div>
                                    <div className="text-xs italic font-medium text-neutral-500">{detail.start_year} - {detail.end_year}</div>
                                  </dd>
                                  <dt className="space-y-1.5">
                                    <div className="text-xs font-medium text-neutral-300"><span className="text-neutral-500 font-normal">Congress:</span> <span>{detail.congress}</span></div>
                                    <div className="text-xs font-medium text-neutral-300"><span className="text-neutral-500 font-normal">District:</span> <span>{detail.district}</span></div>
                                  </dt>
                                </div>
                              )
                            })
                          }
                        </dl>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 border rounded border-[#222]">
                    <div className="bg-neutral-900 p-4 text-xl text-white font-semibold rounded">
                      - &nbsp;Party History
                    </div>
                    <div className="px-2 sm:px-5 py-2">
                      <div className="">
                        <dl className="divide-y divide-neutral-800">
                          {
                            memberPartyHistory.map((detail) => {
                              return(
                                <div className="px-4 py-3 grid grid-cols-3 gap-4 sm:px-0">
                                  <dd className="text-xs text-white col-span-2">{detail.party_name}</dd>
                                  <dt className="text-xs font-medium text-neutral-300">{detail.start_year}</dt>
                                </div>
                              )
                            })
                          }
                        </dl>
                      </div>
                    </div>
                  </div>
                  </div>
                  </div>
                  </>
                  }
                </div>
              </Modal>
              </>
            }
            <div className="filters">
              <div className="flex flex-wrap justify-start items-center filters-select">
                <div className="select-group">
                  <button className="!rounded-[0.15rem]">
                    <PiSlidersHorizontal size={24} color={'#C2C2C2'}/>
                  </button>
                </div>
                <div className="select-group">
                  <button>Party</button>
                  <MultiSelect options={partyOptions} value={selectedPartyOptions} onChange={setSelectedPartyOptions} labelledBy="Party" />
                </div>
                <div className="select-group">
                  <button>State</button>
                  <MultiSelect options={stateOptions} value={selectedStateOptions} onChange={setSelectedStateOptions} labelledBy="State" />
                </div>
                <div className="select-group">
                  <button>Chamber</button>
                  <MultiSelect options={chamberOptions} value={selectedChamberOptions} onChange={setSelectedChamberOptions} labelledBy="Chamber" />
                </div>
              </div>
            </div>
          <DataTable data={listings} columns={columns} rowClickHandler={handleRowClick}/>
          </>
        )}
      </section>
    </AppLayout>
  );
};

export default AllListings;
