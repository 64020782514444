import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams } from "react-router-dom"; // Assuming you're using React Router
import AppLayout from "../layouts/AppLayout";
import { AppContext } from "../contexts/AppContext";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import "../styles/member.scss";
import { HiMiniChevronDown } from "react-icons/hi2";
import { format } from "date-fns";

const Member = () => {
  const { getmember, getlists, savetolist, getmembersponsoredlegislation, getmembercosponsoredlegislation } = useContext(AppContext);
  const { memberId } = useParams(); // Get the memberId from the route
  const [member, setMember] = useState(null);
  const [memberTerms, setMemberTerms] = useState([]);
  const [memberPartyHistory, setMemberPartyHistory] = useState([]);
  const [memberLoading, setMemberLoading] = useState(true);
  const [memberSponsoredLegislation, setMemberSponsoredLegislation] = useState([]);
  const [memberCoSponsoredLegislation, setMemberCoSponsoredLegislation] = useState([]);
  const [lists, setLists] = useState([]);
  const [showListsDropdown, setShowListsDropdown] = useState(false);

  // Sponsored Legislations State
  const [sponsoredLegislation, setSponsoredLegislation] = useState([]);
  const [sponsoredOffset, setSponsoredOffset] = useState(0);
  const [sponsoredHasMore, setSponsoredHasMore] = useState(true);
  const sponsoredRef = useRef(null);
  const sponsoredLoading = useRef(false);

  // Cosponsored Legislations State
  const [cosponsoredLegislation, setCosponsoredLegislation] = useState([]);
  const [cosponsoredOffset, setCosponsoredOffset] = useState(0);
  const [cosponsoredHasMore, setCosponsoredHasMore] = useState(true);
  const cosponsoredRef = useRef(null);
  const cosponsoredLoading = useRef(false);

  const memberDetails = [
    {
      label: "Full Name",
      value:
        (member?.honorific_name ? member.honorific_name + " " : "") +
        member?.first_name +
        " " +
        member?.last_name,
    },
    { label: "State", value: member?.state },
    { label: "District", value: member?.district },
    { label: "Party", value: member?.party_name },
    { label: "Chamber", value: member?.chamber },
    { label: "Career", value: `${member?.start_year} - ${member?.end_year}` },
  ];

  const memberDetails2 = [
    { label: "Birth Year", value: member?.birth_year },
    { label: "Website", value: member?.official_website_url },
    { label: "Sponsored Legislative", value: member?.sponsored_legislation_count.toLocaleString() },
    { label: "Cosponsored Legislative", value: member?.cosponsored_legislation_count.toLocaleString() },
    { label: "Current Member", value: (member?.current_member == 1 ? 'Yes' : 'No') },
    {
      label: "Last Updated",
      value: member?.update_date
        ? format(new Date(member.update_date.replace(" ", "T")), "do MMM yyyy")
        : "",
    },
  ];

  useEffect(() => {
    const fetchMember = async () => {
      if (memberId) {
        setMemberLoading(true);
        const response = await getmember(memberId);
        setMember(response.basic[0]);
        setMemberTerms(response.terms);
        setMemberPartyHistory(response.partyHistory);
        setMemberLoading(false);
      }
    };

    const fetchLists = async () => {
      const response = await getlists();
      setLists(response);
    };

    fetchMember();
    fetchLists();
  }, [memberId]);



  const fetchSponsoredLegislation = async () => {
    if (sponsoredLoading.current || !sponsoredHasMore) return;
  
    sponsoredLoading.current = true;
  
    const response = await getmembersponsoredlegislation(memberId, sponsoredOffset, 20);
  
    if (response?.length > 0) {
      setSponsoredLegislation((prev) => [...prev, ...response]);
      setSponsoredOffset((prev) => prev + 20);
    } else {
      setSponsoredHasMore(false);
    }
  
    sponsoredLoading.current = false;
  };
  
  const fetchCosponsoredLegislation = async () => {
    if (cosponsoredLoading.current || !cosponsoredHasMore) return;
  
    cosponsoredLoading.current = true;
  
    const response = await getmembercosponsoredlegislation(memberId, cosponsoredOffset, 20);
  
    if (response?.length > 0) {
      setCosponsoredLegislation((prev) => [...prev, ...response]);
      setCosponsoredOffset((prev) => prev + 20);
    } else {
      setCosponsoredHasMore(false);
    }
  
    cosponsoredLoading.current = false;
  };
  

  const handleSponsoredScroll = () => {
    if (
      sponsoredRef.current &&
      sponsoredRef.current.scrollTop + sponsoredRef.current.clientHeight >= sponsoredRef.current.scrollHeight - 10
    ) {
      fetchSponsoredLegislation();
    }
  };
  
  const handleCosponsoredScroll = () => {
    if (
      cosponsoredRef.current &&
      cosponsoredRef.current.scrollTop + cosponsoredRef.current.clientHeight >= cosponsoredRef.current.scrollHeight - 10
    ) {
      fetchCosponsoredLegislation();
    }
  };
  

  // Attach Scroll Listeners
  // useEffect(() => {
  //   if (sponsoredRef.current) {
  //     sponsoredRef.current.addEventListener("scroll", handleSponsoredScroll);
  //   }
  //   if (cosponsoredRef.current) {
  //     cosponsoredRef.current.addEventListener("scroll", handleCosponsoredScroll);
  //   }

  //   return () => {
  //     if (sponsoredRef.current) {
  //       sponsoredRef.current.removeEventListener("scroll", handleSponsoredScroll);
  //     }
  //     if (cosponsoredRef.current) {
  //       cosponsoredRef.current.removeEventListener("scroll", handleCosponsoredScroll);
  //     }
  //   };
  // }, [sponsoredHasMore, cosponsoredHasMore]);

  // Initial Fetch
  useEffect(() => {
    if (memberId) {
      fetchSponsoredLegislation();
      fetchCosponsoredLegislation();
    }
  }, [memberId]);

  const saveToList = async (listId) => {
    if (memberId) {
      const response = await savetolist(memberId, listId);
      if (response === "success") {
        setShowListsDropdown(false);
      }
    }
  };

  return (
    <AppLayout pageId="member" pageName="Member">
      <section className="member-page">
        {memberLoading ? (
          <div className="flex justify-center w-full py-12">
            <Spinner color="#CCCCCC" size={24} />
          </div>
        ) : (
          <div className="member-modal">
            <div className="w-full flex flex-1 flex-col justify-between">
              <div>
                <div className="flex flex-col sm:flex-row sm:space-x-5">
                  <div>
                    <img
                      className={`rounded-lg border-2 mx-auto ${
                        member?.party_name === "Republican"
                          ? "border-red-500"
                          : "border-blue-500"
                      }`}
                      src={member?.image_url}
                      alt="Member"
                    />
                    <div className="relative mt-3">
                      <div
                        className={`btn-modal-save-to-list w-full flex items-center justify-between space-x-1 ${
                          showListsDropdown && "!rounded-b-none"
                        }`}
                        onClick={() => setShowListsDropdown(!showListsDropdown)}
                      >
                        <span>Save to list</span> <HiMiniChevronDown size={16} />
                      </div>
                      {showListsDropdown && (
                        <div className="save-list-dropdown">
                          {lists.map((list) => (
                            <div
                              key={list.listid}
                              className="dropdown-item"
                              onClick={() => saveToList(list.listid)}
                            >
                              {list.name}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex-1">
                    <div className="grid grid-cols-1 md:grid-cols-2 sm:gap-10 mt-5 sm:mt-0">
                      <div>
                        <dl className="divide-y divide-neutral-800">
                          {memberDetails.map((detail, index) => (
                            <div
                              key={index}
                              className="px-0 py-3 sm:grid sm:grid-cols-3 sm:gap-4"
                            >
                              <dt className="text-sm text-neutral-500">{detail.label}</dt>
                              <dd className="mt-1 text-sm font-medium text-white sm:col-span-2 sm:mt-0">
                                {detail.value}
                              </dd>
                            </div>
                          ))}
                        </dl>
                      </div>
                      <div className="border-t border-neutral-800 sm:border-t-0">
                        <dl className="divide-y divide-neutral-800">
                          {memberDetails2.map((detail, index) => (
                            <div
                              key={index}
                              className="px-0 py-3 sm:grid sm:grid-cols-3 sm:gap-4"
                            >
                              <dt className="text-sm text-neutral-500">{detail.label}</dt>
                              <dd className="mt-1 text-sm font-medium text-white sm:col-span-2 sm:mt-0">
                                {detail.value}
                              </dd>
                            </div>
                          ))}
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Sponsored Legislation Section */}
                <div className="mt-6 border rounded border-[#222]">
                  <div className="flex justify-between bg-neutral-900 p-4 rounded">
                    <div className="text-xl text-white font-semibold ">
                      - &nbsp;Sponsored Legislations
                    </div>
                    <div>
                      <span className="bg-neutral-800 rounded p-1 px-2">{member?.sponsored_legislation_count.toLocaleString()}</span>
                    </div>
                  </div>
                  <div className="px-2 sm:px-5 py-5 space-y-4 legislations" ref={sponsoredRef} onScroll={handleSponsoredScroll}>
                    {/* <dl className=" "> */}
                      {sponsoredLegislation.map((legislation, index) => (
                        <div key={index} className="px-4 py-4 bg-neutral-950 rounded border border-neutral-800">
                          <div className="flex space-x-4 justify-between items-center">
                            <div className="flex space-x-3">
                              { legislation.introduced_date != '' && 
                              <span className="inline-flex items-center rounded-sm bg-neutral-900 px-2 py-1 text-xs text-neutral-500 ring-1 ring-inset ring-neutral-500/10 mb-2"> Date: <span className="text-neutral-400 ml-1">{format(new Date(legislation.introduced_date.replace(" ", "T")), "do MMM yyyy")}</span> </span>
                              }
                              <span className="inline-flex items-center rounded-sm bg-neutral-900 px-2 py-1 text-xs text-neutral-500 ring-1 ring-inset ring-neutral-500/10 mb-2"> Type: <span className="text-neutral-400 ml-1">{legislation.type}</span> </span>
                            </div>
                            <div className="flex space-x-3">
                              <span className="inline-flex items-center rounded-sm bg-neutral-900 px-2 py-1 text-xs text-neutral-500 ring-1 ring-inset ring-neutral-500/10 mb-2"> Congress: <span className="text-neutral-400 ml-1">{legislation.congress}</span> </span>
                              <span className="inline-flex items-center rounded-sm bg-neutral-900 px-2 py-1 text-xs text-neutral-500 ring-1 ring-inset ring-neutral-500/10 mb-2"> Number: <span className="text-neutral-400 ml-1">{legislation.number}</span> </span>
                              <span className="inline-flex items-center rounded-sm bg-neutral-900 px-2 py-1 text-xs text-neutral-500 ring-1 ring-inset ring-neutral-500/10 mb-2"> Policy Area: <span className="text-neutral-400 ml-1">{legislation.policy_area_name}</span> </span>
                            </div>
                          </div>
                          <div className="text-sm font-normal text-neutral-200 leading-loose">
                            {legislation.title}
                          </div>
                          <hr className="my-3 border-neutral-800"/>
                          <div className="font-medium text-sm">Action Taken: </div>
                          <div className="text-xs text-neutral-400 font-medium mt-2">
                          { legislation.latest_action_date ? format(new Date(legislation.latest_action_date.replace(" ", "T")), "do MMM yyyy") : ''} - {legislation.latest_action_text}</div>
                        </div>
                      ))}
                      {sponsoredHasMore && (
                        <div className="flex justify-center py-4">
                          <Spinner color="#CCCCCC" size={24} />
                        </div>
                      )}
                    {/* </dl> */}
                  </div>
                </div>
                {/* CoSponsored Legislation Section */}
                <div className="mt-6 border rounded border-[#222]">
                  <div className="flex justify-between bg-neutral-900 p-4 rounded">
                    <div className="text-xl text-white font-semibold ">
                      - &nbsp;CoSponsored Legislations
                    </div>
                    <div>
                      <span className="bg-neutral-800 rounded p-1 px-2">{member?.cosponsored_legislation_count.toLocaleString()}</span>
                    </div>
                  </div>
                  <div className="px-2 sm:px-5 py-5 space-y-4 legislations" ref={cosponsoredRef} onScroll={handleCosponsoredScroll}>
                    {/* <dl className="space-y-4"> */}
                      {cosponsoredLegislation.map((legislation, index) => (
                        <div key={index} className="px-4 py-4 bg-neutral-950 rounded border border-neutral-800">
                          <div className="flex space-x-4 justify-between items-center">
                            <div className="flex space-x-3">
                              { (legislation.introduced_date != '' && legislation.introduced_date != null) ? 
                              <span className="inline-flex items-center rounded-sm bg-neutral-900 px-2 py-1 text-xs text-neutral-500 ring-1 ring-inset ring-neutral-500/10 mb-2"> Date: <span className="text-neutral-400 ml-1">{format(new Date(legislation.introduced_date.replace(" ", "T")), "do MMM yyyy")}</span> </span> : ''
                              }
                              <span className="inline-flex items-center rounded-sm bg-neutral-900 px-2 py-1 text-xs text-neutral-500 ring-1 ring-inset ring-neutral-500/10 mb-2"> Type: <span className="text-neutral-400 ml-1">{legislation.type}</span> </span>
                            </div>
                            <div className="flex space-x-3">
                              <span className="inline-flex items-center rounded-sm bg-neutral-900 px-2 py-1 text-xs text-neutral-500 ring-1 ring-inset ring-neutral-500/10 mb-2"> Congress: <span className="text-neutral-400 ml-1">{legislation.congress}</span> </span>
                              <span className="inline-flex items-center rounded-sm bg-neutral-900 px-2 py-1 text-xs text-neutral-500 ring-1 ring-inset ring-neutral-500/10 mb-2"> Number: <span className="text-neutral-400 ml-1">{legislation.number}</span> </span>
                              <span className="inline-flex items-center rounded-sm bg-neutral-900 px-2 py-1 text-xs text-neutral-500 ring-1 ring-inset ring-neutral-500/10 mb-2"> Policy Area: <span className="text-neutral-400 ml-1">{legislation.policy_area_name}</span> </span>
                            </div>
                          </div>
                          <div className="text-sm font-normal text-neutral-200 leading-loose">
                            {legislation.title}
                          </div>
                          <hr className="my-3 border-neutral-800"/>
                          <div className="font-medium text-sm">Action Taken: </div>
                          <div className="text-xs text-neutral-400 font-medium mt-2">
                          { legislation.latest_action_date ? format(new Date(legislation.latest_action_date.replace(" ", "T")), "do MMM yyyy") : ''} - {legislation.latest_action_text}</div>
                        </div>
                      ))}
                      {cosponsoredHasMore && (
                        <div className="flex justify-center py-4">
                          <Spinner color="#CCCCCC" size={24} />
                        </div>
                      )}
                    {/* </dl> */}
                  </div>
                </div>
                {/* Terms Section */}
                <div className="mt-6 border rounded border-[#222]">
                  <div className="bg-neutral-900 p-4 text-xl text-white font-semibold rounded">
                    - &nbsp;Terms
                  </div>
                  <div className="px-2 sm:px-5 py-2 divide-y divide-neutral-800 terms">
                    {/* <dl className=""> */}
                      {memberTerms.map((term, index) => (
                        <div
                          key={index}
                          className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
                        >
                          <dd className="space-y-1.5 sm:col-span-2">
                            <div className="text-sm font-medium text-white">
                              {term.chamber}{" "}
                              <span className="text-neutral-600 text-xs font-medium">
                                - {term.member_type}
                              </span>
                            </div>
                            <div className="text-sm text-neutral-400">
                              {term.state_name}
                            </div>
                            <div className="text-xs italic font-medium text-neutral-500">
                              {term.start_year} - {term.end_year}
                            </div>
                          </dd>
                          <dt className="space-y-1.5">
                            <div className="text-xs font-medium text-neutral-300">
                              <span className="text-neutral-500 font-normal">Congress:</span>{" "}
                              <span>{term.congress}</span>
                            </div>
                            <div className="text-xs font-medium text-neutral-300">
                              <span className="text-neutral-500 font-normal">District:</span>{" "}
                              <span>{term.district}</span>
                            </div>
                          </dt>
                        </div>
                      ))}
                    {/* </dl> */}
                  </div>
                </div>
                {/* Party History Section */}
                <div className="mt-6 border rounded border-[#222]">
                  <div className="bg-neutral-900 p-4 text-xl text-white font-semibold rounded">
                    - &nbsp;Party History
                  </div>
                  <div className="px-2 sm:px-5 py-2 divide-y divide-neutral-800 party-history">
                    {/* <dl className=""> */}
                      {memberPartyHistory.map((history, index) => (
                        <div
                          key={index}
                          className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
                        >
                          <dd className="text-xs text-white sm:col-span-2">
                            {history.party_name}
                          </dd>
                          <dt className="text-xs font-medium text-neutral-300">
                            {history.start_year}
                          </dt>
                        </div>
                      ))}
                    {/* </dl> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </AppLayout>
  );
};

export default Member;
