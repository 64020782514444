import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FiUsers, FiUser, FiLogOut } from "react-icons/fi";
import { FaBars } from "react-icons/fa6";
import { LuSettings } from "react-icons/lu";
import { PiPassword } from "react-icons/pi";
import { AppContext } from "../contexts/AppContext";
import "../styles/dashboard.scss";
import "../styles/table.scss";
import { HiMiniChevronDown } from "react-icons/hi2";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { GrHomeRounded } from "react-icons/gr";
import { FaListUl } from "react-icons/fa6";
import { TbLogout } from "react-icons/tb";
import { LuSearch } from "react-icons/lu";
import { motion } from "framer-motion";
import { RxDoubleArrowLeft } from "react-icons/rx";

const AppLayout = ({ children, pageName, pageId, extraHtml }) => {
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(() => {
    return localStorage.getItem("isSidebarOpen") === "true";
  });
  const [activeDropdown, setActiveDropdown] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const sidebarRef = useRef(null);
  const { logout } = useContext(AppContext);
  const userToken = JSON.parse(localStorage.getItem("userToken"));

  useEffect(() => {
    if (userToken == null) {
      navigate("/");
    }
    else if (userToken.company == null || userToken.company == '') {
    }
  }, []);

  const handleLogout = async () => {
    const response = await logout();
    if (response === "success") {
      navigate("/");
    }
  };

  const handleSidebarToggle = () => {
    setIsSidebarOpen((prevState) => {
      const newState = !prevState;
      localStorage.setItem("isSidebarOpen", newState);
      return newState;
    });
  };

  const handleDropdownToggle = (dropdown) => {
    setActiveDropdown((prev) => (prev === dropdown ? "" : dropdown));
  };

  useEffect(() => {
    const handleLinkClick = (event) => {
      if (event.target.closest(".nav-main") && window.innerWidth <= 786) {
        setIsSidebarOpen((prevState) => {
          const newState = !prevState;
          localStorage.setItem("isSidebarOpen", newState);
          return newState;
        });
      }
    };

    const sidebar = document.getElementById("sidebar-wrapper");
    if (sidebar) {
      sidebar.addEventListener("click", handleLinkClick);
    }

    return () => {
      if (sidebar) {
        sidebar.removeEventListener("click", handleLinkClick);
      }
    };
  }, []);

  return (
    <>
      {userToken != null ? (
        <div className={`d-flex ${isSidebarOpen ? "toggled" : "untoggled"}`} id="wrapper" ref={sidebarRef}>
          <div id="sidebar-wrapper" className="">
            <div className="sidebar-heading">
              <img src="/logo.png" className="logo invert" />
              {isSidebarOpen && 
              <button className="block sm:hidden mr-2" onClick={handleSidebarToggle} id="menu-toggle">
                <RxDoubleArrowLeft color="#FFF" size={42} />
              </button>
              }
            </div>
            <div className="list-group list-group-flush pt-1.5 pb-3">
              <div className="w-100">
                <div className="px-2 d-flex">
                  {userToken.type == "Admin" ? (
                    <>
                      <Link to="/dashboard" className={`nav-main ${pageName == "Dashboard" || pageName == "Member" ? "active-link" : ""}`}>
                        <div className="nav-hide">
                          <GrHomeRounded /> <span>Dashboard</span>
                        </div>
                        <div className="nav-icon">
                          <GrHomeRounded />
                        </div>
                      </Link>
                      <Link to="/all-clients" className={`nav-main ${pageName == "All Clients" || pageName == "Add Client" || pageName == "Update Client" ? "active-link" : ""}`}>
                        <div className="nav-hide">
                          <FiUsers /> <span>Clients</span>
                        </div>
                        <div className="nav-icon">
                          <FiUsers />
                        </div>
                      </Link>
                      <Link to="/all-listings" className={`nav-main ${pageName == "All Listings" || pageName == "Add Listing" || pageName == "Update Listing" ? "active-link" : ""}`}>
                        <div className="nav-hide">
                          <FaListUl /> <span>Listings</span>
                        </div>
                        <div className="nav-icon">
                          <FaListUl />
                        </div>
                      </Link>
                    </>
                  ) : (
                    ""
                  )}

                  {userToken.type == "Organization" ? (
                    <>
                      <Link to="/discover" className={`nav-main ${pageName == "Discover" || pageName == "Member" ? "active-link" : ""}`}>
                        <div className="nav-hide"> <LuSearch /> <span>Discover</span> </div>
                        <div className="nav-icon"> <LuSearch /> </div>
                      </Link>
                      <Link to="/lists" className={`nav-main ${pageName == "Lists" || pageName == "List Details" ? "active-link" : ""}`}>
                        <div className="nav-hide">
                          <FaListUl /> <span>Lists</span>
                        </div>
                        <div className="nav-icon">
                          <FaListUl />
                        </div>
                      </Link>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="block sm:hidden">
                    <Link to="/profile" className={`nav-main ${pageName == "My Profile" ? "active-link" : ""}`}>
                      <div className="nav-hide">
                        <FiUser /> <span>Profile</span>
                      </div>
                      <div className="nav-icon">
                        <FiUser />
                      </div>
                    </Link>
                    <Link to="/password" className={`nav-main ${pageName == "Change Password" ? "active-link" : ""}`}>
                      <div className="nav-hide">
                        <PiPassword /> <span>Password</span>
                      </div>
                      <div className="nav-icon">
                        <PiPassword />
                      </div>
                    </Link>
                  </div>
                  <div className="hidden sm:block">
                    <Link onClick={() => handleDropdownToggle('Settings')} className={`nav-main hide-in-toggled ${pageName == "Settings" || pageName == "My Profile" || pageName == "Change Password" ? "active-link" : ""}`}>
                      <div className="nav-hide"> <LuSettings  /> <span>Settings</span> </div>
                      <div className="nav-icon"> <LuSettings  /> </div>
                      <div><HiMiniChevronDown/></div>
                    </Link>
                    <div className={`ml-10 pl-0.5 flex flex-col text-sm mb-3 dropdown-menu ${(activeDropdown !== 'Settings' && !isSidebarOpen) && 'hidden'}`}>
                      <div><Link to="/profile" className={`${isSidebarOpen ? "nav-main" : "p-2 rounded-md"} ${pageName == "My Profile" ? "active-link" : ""}`}><FiUser /> <span>Profile</span></Link></div>
                      <div><Link to="/password" className={`${isSidebarOpen ? "nav-main" : "p-2 rounded-md"} ${pageName == "Change Password" ? "active-link" : ""}`}><PiPassword /> <span>Password</span></Link></div>
                      <div><Link onClick={handleLogout} className={`${isSidebarOpen ? "nav-main" : "p-2 rounded-md"}`}><TbLogout /> <span>Logout</span></Link></div>
                    </div>
                  </div>
                  <Link to="/" className="nav-main hide-in-toggled" onClick={handleLogout}>
                    <div className="nav-hide"> <TbLogout /> <span>Logout</span> </div>
                    <div className="nav-icon"> <TbLogout /> </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div id="page-content-wrapper">
            <div id="page-content">
              {extraHtml}
              <nav className="">
                <div className="flex flex-wrap items-center justify-between mx-auto py-2">
                  <div className="flex items-center">
                    <div>
                      <FaBars className="text-white mr-1 cursor-pointer" size={24} onClick={handleSidebarToggle}/>
                    </div>
                    <div className="page-head">{pageName != "" ? pageName : ""}</div>
                  </div>
                  <div className="items-center justify-between hidden w-full md:flex md:w-auto nav-right space-x-10" id="navbar-search">
                    <div className="relative">
                      <button type="button" onClick={() => handleDropdownToggle('User')} className={`user-dropdown btn-main-nav pr-5 py-1.5 text-center inline-flex items-center ${activeDropdown === 'User' && '!bg-white'}`}>
                        {userToken.name + " " + userToken.lastname} {activeDropdown === 'User' ? <IoIosArrowUp className="ml-1.5"/> : <IoIosArrowDown className="ml-1.5"/>} 
                      </button>
                      {activeDropdown === 'User' && 
                      <div id="dropdown" className={`z-10 absolute left-0 bg-main-alt divide-y divide-gray-100 rounded-lg shadow w-full`}>
                          <ul className="p-1 text-xs text-gray-400" aria-labelledby="dropdownDefaultButton">
                            <li>
                              <Link to="/profile" className="block px-3 py-2 rounded hover:bg-gray-100 hover:text-gray-800">Profile</Link>
                            </li>
                            <li>
                              <Link to="/password" className="block px-3 py-2 rounded hover:bg-gray-100 hover:text-gray-800">Password</Link>
                            </li>
                            <li>
                              <Link to="/" onClick={handleLogout} className="block px-3 py-2 rounded hover:bg-red-500 hover:text-white">Logout</Link>
                            </li>
                          </ul>
                      </div>
                      }
                    </div>
                  </div>
                </div>
              </nav>
              <div className="main-body" id={pageId != "" ? pageId : ""}>
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                  {children}
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default AppLayout;
