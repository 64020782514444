import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import BASE_URL from "../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import io from "socket.io-client";
import { AuthContext } from "./AuthContext";

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const { userToken, setIsLoggedIn, isLoggedIn, setuserToken } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [searchloading, setSearchloading] = useState(false);
  const [isUnreadLoading, setIsUnreadLoading] = useState(true);
  const [apperror, setApperror] = useState("");
  // const [userToken, setuserToken] = useState('');
  const [totalUnread, setTotalUnread] = useState([]);
  const [socket, setSocket] = useState(null);
  const sessionToken = JSON.parse(localStorage.getItem("userToken"));

  const verifySession = async () => {
    if (userToken) {
      // Only run if userToken is available
      try {
        const response = await axios.post(`${BASE_URL}/user/verifysession`, {
          userid: userToken.userid,
        });

        if (response.data.message === "success") {
          localStorage.setItem("userToken", JSON.stringify(response.data.userToken[0]));

          const token = JSON.parse(localStorage.getItem("userToken"));
          setuserToken(token);
        } else {
          logout();
        }
      } catch (error) {
        console.error("Verify failed: " + error);
        logout(); // Consider logging out on error as well
      }
    }
  };

  const completeonboarding = async (company, name) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/user/onboard`, {
        company,
        name,
        userid: userToken.userid,
      });

      if (response.data.message === "success") {
        localStorage.setItem("userToken", JSON.stringify(response.data.userToken[0]));
        // toast.success("Profile Updated Successfully!");
        setIsLoading(false);
        return "success";
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Request failed: " + error);
    }
    setIsLoading(false);
  };

  const fetchUnreadMessages = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/messages/allunread`, {
        userid: userToken.userid,
        usertype: userToken.type,
      });
      if (response.data.message === "success") {
        setTotalUnread(response.data.result);
      }
    } catch (error) {
      console.error("Error fetching unread messages:", error);
    } finally {
      setIsUnreadLoading(false);
    }
  };

  // useEffect(() => {
  //   // Initialize socket connection only once when the component mounts
  //   // console.log("Initializing socket connection");
  //   // const newSocket = io(BASE_URL);
  //   const newSocket = io(BASE_URL.replace("api", ""));
  //   setSocket(newSocket);

  //   // Clean up the socket connection when the component unmounts
  //   return () => {
  //     newSocket.close();
  //   };
  // }, [isLoggedIn]); // Empty dependency array to run only once

  useEffect(() => {
    // Verify session periodically
    const interval = setInterval(verifySession, 60000 * 1); // Check every minute
    return () => clearInterval(interval);
  }, [userToken]); // Depend on userToken

  // useEffect(() => {
  //   if (socket && userToken && userToken.userid) {
  //     socket.emit("register user", userToken.userid);
  //     fetchUnreadMessages();

  //     socket.on("new unread message", (message) => {
  //       // Handle new unread message
  //       setTotalUnread((prev) => [...prev, message]);
  //       toast(<div dangerouslySetInnerHTML={{ __html: '<b className="text-main text-sm">' + message.title + '</b><br /><span className="text-main text-lg">✉</span> ' + (message.message.length > 30 ? message.message.slice(0, 30) + "..." : message.message) }} />);
  //     });

  //     return () => {
  //       socket.off("new unread message");
  //     };
  //   }
  // }, [socket, userToken]);

  const getdashboard = async () => {
    try {
      setIsLoading(true);
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/dashboard/stats`, {
        userid: userToken.userid,
        usertype: userToken.type,
      });

      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const getclients = async (statusfilter) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/clients/all`, { statusfilter });

      if (response.data.message === "success") {
        setIsLoading(false);
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const getorganizations = async () => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/clients/organizations`, {
        userid: userToken.userid
      });

      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
    }
  };

  const getclient = async (userid) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/clients/get`, {
        userid,
      });

      if (response.data.message === "success") {
        setIsLoading(false);
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const reviewclient = async ({ userid, reviewaction }) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/clients/review`, {
        userid,
        reviewaction,
      });

      if (response.data.message === "success") {
        setIsLoading(false);
        toast.success("Client Account " + reviewaction + " Successfully!");
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const addclient = async (organization, organizationid, title, name, lastname, phone, email, password, acctype) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/clients/add`, {
        organization, organizationid, title, name, lastname, phone, email, password, acctype, userid: userToken.userid
      });

      if (response.data.message === "success") {
        toast.success("Client Added Successfully!");
        return "success";
      } else {
        toast.error(response.data.message);
        return response.data.message;
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateclient = async (organization, organizationid, title, name, lastname, phone, email, password, acctype, userId) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/clients/update`, {
        organization, organizationid, title, name, lastname, phone, email, password, acctype, userid: userId,
      });

      if (response.data.message === "success") {
        toast.success("Client Updated Successfully!");
        setIsLoading(false);
      } else {
        toast.error(response.data.message);
      }

      return response.data.message;
      
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteclient = async (userid) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/clients/delete`, {
        userid,
      });

      if (response.data.message === "success") {
        setIsLoading(false);
        toast.success("Client Deleted Successfully!");
        return "success";
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    }
    setIsLoading(false);
  };




  const getlistings = async (statusfilter, partyFilter, stateFilter, chamberFilter) => {
    try {
      const response = await axios.post(`${BASE_URL}/listings/all`, { statusfilter, partyFilter, stateFilter, chamberFilter });

      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };


  const getlistingsx = async (statusfilter, limit, offset) => {
    try {
      if(offset == 0){
      // setIsLoading(true);
      }
      const response = await axios.post(`${BASE_URL}/listings/all`, { statusfilter, limit, offset });

      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const deletelisting = async (userid) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/listings/delete`, {
        userid,
      });

      if (response.data.message === "success") {
        setIsLoading(false);
        toast.success("Listing Deleted Successfully!");
        return "success";
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    }
    setIsLoading(false);
  };




  const getmembers = async (statusfilter, limit, offset, partyFilter, stateFilter, chamberFilter) => {
    try {
      if(offset == 0){
      // setIsLoading(true);
      }
      const response = await axios.post(`${BASE_URL}/members/all`, { statusfilter, limit, offset, partyFilter, stateFilter, chamberFilter });

      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const getdiscoveroptions = async () => {
    try {
      setIsLoading(true);
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/members/options`);
      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const getmember = async ( memberid ) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/members/get`, {
        memberid, listid: userToken.teamid, userid: userToken.userid
      });

      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
    }
  };

  const getmembersponsoredlegislation = async (memberId, offset = 0, limit = 20) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/member/sponsoredlegislation/${memberId}`, {
        userid: userToken.userid,
        offset,
        limit,
      });
  
      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
        return [];
      }
    } catch (error) {
      toast.error("Failed: " + error.message);
      return [];
    }
  };
  
  const getmembercosponsoredlegislation = async (memberId, offset = 0, limit = 20) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/member/cosponsoredlegislation/${memberId}`, {
        userid: userToken.userid,
        offset,
        limit,
      });
  
      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
        return [];
      }
    } catch (error) {
      toast.error("Failed: " + error.message);
      return [];
    }
  };  

  const saveit = async ( memberid ) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/lists/save`, {
        memberid, listid: userToken.userid, userid: userToken.userid
      });

      if (response.data.message === "success") {
        toast.success('Saved Successfully!');
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
    }
  };

  const unsaveit = async ( memberid ) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/lists/unsave`, {
        memberid, listid: userToken.userid, userid: userToken.userid
      });

      if (response.data.message === "success") {
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
    }
  };

  const sharewithteam = async ( memberid ) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/lists/sharewithteam`, {
        memberid, listid: userToken.teamid, userid: userToken.userid
      });

      if (response.data.message === "success") {
        toast.success('Shared with team Successfully!');
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
    }
  };

  const savetolist = async ( memberid, listId ) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/lists/save`, {
        memberid, listid: listId, userid: userToken.userid
      });

      if (response.data.message === "success") {
        toast.success('Saved to List');
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
    }
  };

  const bulkremovefromlist = async ( memberid, listId ) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/lists/bulkremove`, {
        memberid, listid: listId, userid: userToken.userid
      });

      if (response.data.message === "success") {
        toast.success('Removed from List');
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
    }
  };

  const addnewlist = async ( name ) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/lists/add`, {
        name, userid: userToken.userid
      });

      if (response.data.message === "success") {
        toast.success('List Added Successfully!');
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
    }
  };

  const getlists = async ( memberid ) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/lists/all`, {
        userid: userToken.userid
      });

      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
    }
  };

  const getlist = async ( listId ) => {
    try {
      setIsLoading(true);
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/lists/get`, {
        userid: userToken.userid, listid: listId
      });

      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const getlistmembers = async (statusfilter, listId, limit, offset) => {
    try {
      if(offset == 0){
      // setIsLoading(true);
      }
      const response = await axios.post(`${BASE_URL}/lists/members`, { statusfilter, listId, limit, offset });

      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const removefromlist = async ( memberid, listId ) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/lists/members/remove`, {
        memberid, listid: listId, userid: userToken.userid
      });

      if (response.data.message === "success") {
        toast.success('Removed from List');
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
    }
  };

  const reviewmember = async ({ userid, reviewaction }) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/members/review`, {
        userid,
        reviewaction,
      });

      if (response.data.message === "success") {
        setIsLoading(false);
        toast.success("Member Account " + reviewaction + " Successfully!");
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const updatemember = async ({ company, name, lastname, email, phone, address, userId }) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/members/update`, {
        company,
        name,
        lastname,
        email,
        phone,
        address,
        userid: userId,
      });

      if (response.data.message === "success") {
        toast.success("Member Updated Successfully!");
        setIsLoading(false);
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const deletemember = async (userid) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/members/delete`, {
        userid,
      });

      if (response.data.message === "success") {
        setIsLoading(false);
        toast.success("Member Deleted Successfully!");
        return "success";
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    }
    setIsLoading(false);
  };

  const updateprofile = async (company, name, lastname, email, phone) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/user/update`, {
        company,
        name,
        lastname,
        email,
        phone,
        userid: userToken.userid,
      });

      if (response.data.message === "success") {
        localStorage.setItem("userToken", JSON.stringify(response.data.userToken[0]));
        toast.success("Profile Updated Successfully!");
        setIsLoading(false);
        return "success";
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Request failed: " + error);
    }
    setIsLoading(false);
  };

  const updateprofilepicture = async (image) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/user/updateprofilepicture`, {
        image,
        userid: userToken.userid,
      });

      if (response.data.message === "success") {
        localStorage.setItem("userToken", JSON.stringify(response.data.userToken[0]));
        toast.success("Image Updated Successfully!");
        setIsLoading(false);
        return "success";
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Request failed: " + error);
    }
    setIsLoading(false);
  };

  const updatepassword = async (oldpassword, newpassword, confirmpassword) => {
    try {
      if (newpassword === confirmpassword) {
        const userToken = JSON.parse(localStorage.getItem("userToken"));
        setIsLoading(true);
        const response = await axios.post(`${BASE_URL}/user/updatepassword`, {
          oldpassword,
          newpassword,
          confirmpassword,
          userid: userToken.userid,
        });

        if (response.data.message === "success") {
          toast.success("Password Updated Successfully!");
          setIsLoading(false);
          return "success";
        } else {
          toast.error(response.data.message);
        }
      } else {
        toast.error("Passwords don't match");
      }
    } catch (error) {
      toast.error("Request failed: " + error);
    }
    setIsLoading(false);
  };

  const logout = () => {
    setIsLoggedIn(false);
    localStorage.clear();
    return "success";
  };

  return (
    <AppContext.Provider
      value={{
        userToken,
        socket,
        isLoading,
        logout,
        getdashboard,
        fetchUnreadMessages,
        completeonboarding,
        getorganizations,
        getclients,
        getclient,
        reviewclient,
        addclient,
        updateclient,
        deleteclient,
        getlistings,
        getmembers,
        getmember,
        getmembersponsoredlegislation,
        getmembercosponsoredlegislation,
        getdiscoveroptions,
        sharewithteam,
        saveit,
        unsaveit,
        savetolist,
        bulkremovefromlist,
        removefromlist,
        addnewlist,
        getlists,
        getlist,
        getlistmembers,
        reviewmember,
        updatemember,
        deletemember,
        deletelisting,
        updateprofile,
        updateprofilepicture,
        updatepassword,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
