import React, { useContext, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import AuthLayout from "../layouts/AuthLayout";
import Bounce from "react-activity/dist/Bounce";
import BASE_URL from "../config";
import "react-activity/dist/Bounce.css";
import { IoMailOutline } from "react-icons/io5";
import { GoLock } from "react-icons/go";

const Home = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ifredirect = searchParams.get("redirect") != null ? searchParams.get("redirect") : "";

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const { login, isLoading, autherror } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading === false) {
      try {
        if (email !== "" && password !== "") {
          setError("");
          const response = await login(email, password);
          if (response === "success") {
            if (ifredirect !== "") {
              navigate(ifredirect);
            } else {
              navigate("/dashboard");
            }
          } else {
            setError(response);
          }
        } else {
          setError("All fields are required");
          return;
        }
      } catch (error) {
        setError("Login failed: " + error);
      }
    }
  };

  return (
    <AuthLayout page="Login" heading="Sign in to Portal" subheading="We suggest using the <span>email address you use at work.</span>">
      {/* <div>
          <div class="pills">
              <button class="active">Login</button>
              <Link to="/register">Register</Link>
          </div>
      </div> */}
      <form onSubmit={handleSubmit} className="login-form">
        <h3>Hello Again</h3>
        <h6>Please sign in to continue</h6>
        <div className="mt-6">
          <div className="space-y-6"> 
            <div className="form-group email">
              <div class="input-icon">
                <IoMailOutline />
              </div>
              <input id="email" name="email" required type="email" className="form-control mt-1" placeholder="hello@policyportal.com" onChange={(e) => setEmail(e.target.value)} value={email} />
            </div>
            <div className="form-group password">
              <div class="input-icon">
                  <GoLock />
              </div>
              <input id="password" name="password" required type="password" className="form-control mt-1" placeholder="Your password" onChange={(e) => setPassword(e.target.value)} value={password} />
            </div>
          </div>
          <div class="my-5"><Link class="font-medium text-sm text-neutral-300" to="/forgot-password">Forgot Password</Link></div>
          {error !== "" ? <div className="text-center text-red-500 font-semibold text-md my-3">{error}</div> : <div className="py-0"></div>}
          <button type="submit" name="submit" className="w-full btn-main-auth" disabled={isLoading}>
            {isLoading === false ? "Sign In" : <Bounce animating={isLoading} />}
          </button>
          <div className="mt-5 text-neutral-500 text-sm font-medium">
            Don't have an account yet? <Link className="text-main font-medium" to="/register">Register now</Link>
          </div>
        </div>
      </form>
    </AuthLayout>
  );
};

export default Home;
